import React from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ApiService from "./ApiService";
import TablePagination from '@material-ui/core/TablePagination';
import ReactDiffViewer from 'react-diff-viewer';
import { Box, Button, Collapse } from '@material-ui/core';

export default class RevisionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      revisions: [],
      numOfRevisions: 0,
      limit: 10,
      page: 0,
      offset: 0,
      config: null,
      openDiff: null
    };
    this.handleError = this.handleError.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.openDiff = this.openDiff.bind(this);
    this.refreshRevisions = this.refreshRevisions.bind(this);
  }


  handleError(err) {
    if (this.props.onError) {
      this.props.onError(err);
    }
  }

  handlePageChange(evt, page) {
    this.setState({page: page, offset: this.state.limit * page}, () => this.refreshRevisions());
  }

  handleLimitChange(evt) {
    this.setState({limit: parseInt(evt.target.value, 10), page: 0, offset: 0}, () => this.refreshRevisions());
  }

  async refreshRevisions() {
    try {
      if (this.props.configId) {
        const data = await ApiService.getConfigRevisions(this.props.configId, this.state.limit, this.state.offset);
        this.setState({
          isLoaded: true,
          revisions: data && data.revisions ? data.revisions : [],
          numOfRevisions: data && data.numberOfRevisions ? data.numberOfRevisions : 0
        });
      }
    } catch (err) {
      this.handleError(err);
      this.setState({
        isLoaded: true,
      });
    }
  }

  async componentDidMount() {
    await this.refreshRevisions();
  }

  async openDiff(revisionId) {
    if (this.state.openDiff === revisionId) {
      this.setState({
        openDiff: null
      });
    } else if (this.state.config === null) {
      const config = await ApiService.getConfig(this.props.configId);
      this.setState({
        openDiff: revisionId,
        config: config
      });
    } else {
      this.setState({
        openDiff: revisionId
      });
    }
  }

  render() {
    const { isLoaded, revisions } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Diff</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody >
                {revisions.map((row) => (
                  <React.Fragment>
                    <TableRow key={row.revisionId}>
                      <TableCell>{row.revisionId}</TableCell>
                      <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{row.createdBy}</TableCell>
                      <TableCell><Button onClick={() => {this.openDiff(row.revisionId)}}>Diff</Button></TableCell>
                      <TableCell/>
                    </TableRow>
                    <TableRow key={'diff_' + row.revisionId}>
                      <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                          <Collapse in={this.state.openDiff === row.revisionId} timeout="auto" unmountOnExit>
                              <Box margin={1}>
                                  <ReactDiffViewer oldValue={JSON.stringify(row.configuration, null, 4)} newValue={JSON.stringify(this.state.config, null, 4)} splitView={true} />
                              </Box>
                          </Collapse>
                        </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            {revisions.length <= 0 &&
              <p className="no-data">No data for preview</p>
            }
          </TableContainer>
          <TablePagination
            component="div"
            count={this.state.numOfRevisions}
            page={this.state.page}
            onPageChange={this.handlePageChange}
            rowsPerPage={this.state.limit}
            onRowsPerPageChange={this.handleLimitChange}
          />
          </div>
      );
    }
  }
}